import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { components, colors } from '@ElementsCapitalGroup/enium-ui'
import Button, { BUTTON_COLORS, BUTTON_VARIANTS } from 'components/button'
import Loader from 'components/loader'
import { showNotification } from 'modules/global/actions'
import { NOTIFICATION_TYPES } from 'modules/global/notifications'
import {
  chooseLoan,
  getAssociatedLoanProductsBasic,
} from '../../../../../actions'

const { Dialog, Autocomplete } = components

const ChangeLoanProductModal = ({
  dispatch,
  loanApplicationId,
  isOpen,
  onClose,
  setModalOpen,
  loanName,
}) => {
  const { t: translate } = useTranslation()
  const [evaluatedLoanProducts, setEvaluatedLoanProducts] = useState([])
  const [loading, setLoading] = useState(false)
  const [selectedLoan, setSelectedLoan] = useState(null)
  const [confirmSelectedLoanModal, setConfirmSelectedLoanModal] =
    useState(false)

  useEffect(() => {
    const initialSelectedLoan = evaluatedLoanProducts.find(
      (itm) => itm.name === loanName
    )
    if (initialSelectedLoan) {
      setSelectedLoan({
        guid: initialSelectedLoan?.loanProductId,
        name: initialSelectedLoan?.name,
        label: initialSelectedLoan?.name,
      })
    } else {
      setSelectedLoan(null)
    }
  }, [loanName, evaluatedLoanProducts])

  const handleSubmit = () => {
    setLoading(true)
    chooseLoan(dispatch, loanApplicationId, selectedLoan.guid)
      .then((res) => {
        if (res) {
          showNotification(dispatch, {
            type: NOTIFICATION_TYPES.POSITIVE,
            title: `Successfully changed loan product`,
          })
          setTimeout(() => {
            window.location.href = `/loan-application/${loanApplicationId}/lender-agreement`
          }, 1000)
        }
        setConfirmSelectedLoanModal(false)
        setModalOpen(false)
      })
      .finally(() => setLoading(false))
  }

  useEffect(() => {
    getAssociatedLoanProductsBasic(loanApplicationId).then((res) =>
      setEvaluatedLoanProducts(res)
    )
  }, [])

  return (
    <Dialog
      open={isOpen}
      title={'Change Loan Product'}
      sx={{
        backdropFilter: 'blur(10px)',
      }}
      PaperProps={{
        sx: {
          maxWidth: '100%',
          width: '400px',
          '& .MuiDialogTitle-root': {
            alignItems: 'center',
            fontSize: '1.125rem',
          },
        },
      }}
      disableEscapeKeyDown={true}
      onClose={onClose}
      actions={
        <>
          <Button
            onClick={onClose}
            color={BUTTON_COLORS.INHERIT}
            variant={BUTTON_VARIANTS.OUTLINED}
            disabled={loading}
          >
            {translate('buttons.cancel')}
          </Button>
          <Button
            disabled={!selectedLoan}
            loading={loading}
            onClick={() => setConfirmSelectedLoanModal(true)}
          >
            {translate('global.saveChanges')}
          </Button>
        </>
      }
      actionsProps={{
        sx: {
          paddingTop: '8px',
          width: '100%',
          justifyContent: 'space-between',
          '& button': {
            width: '50%',
          },
        },
      }}
    >
      <Autocomplete
        options={evaluatedLoanProducts?.map((el) => ({
          guid: el?.loanProductId,
          name: el?.name,
          label: el?.name,
        }))}
        value={selectedLoan}
        onChange={(e, selected) => {
          setSelectedLoan(selected)
        }}
        sx={{ flexGrow: 1 }}
      />

      <Dialog
        open={confirmSelectedLoanModal}
        onClose={() => setConfirmSelectedLoanModal(false)}
        title={translate('loanApplication.step2.confirmSelectedLoan')}
        PaperProps={{
          sx: {
            maxWidth: '100%',
            width: '400px',
            '& .MuiDialogTitle-root': {
              padding: '24px 24px 0',
              fontSize: '1.125rem',
            },
            '& .MuiDialogActions-root': {
              paddingTop: '0',
            },
          },
        }}
        actionsProps={{
          sx: {
            width: '100%',
            justifyContent: 'space-between',
            '& button': {
              width: '50%',
            },
          },
        }}
        actions={
          <>
            <Button
              onClick={() => setConfirmSelectedLoanModal(false)}
              color={BUTTON_COLORS.INHERIT}
              disabled={loading}
              variant={BUTTON_VARIANTS.OUTLINED}
            >
              {translate('buttons.cancel')}
            </Button>
            <Button onClick={handleSubmit} disabled={loading || !selectedLoan}>
              {translate('buttons.confirm')}
            </Button>
          </>
        }
      >
        {loading ? (
          <Loader style={{ marginTop: '12px' }} center />
        ) : (
          <div
            className="delete-modal__question"
            style={{ marginTop: '20px', fontSize: '0.875rem' }}
          >
            <div>
              {translate('loanApplication.step2.areYouSureLoanProduct1')}
              <span style={{ color: colors.blue[500], fontWeight: 600 }}>
                &nbsp;{selectedLoan?.name}
              </span>
              .&nbsp;{translate('loanApplication.step2.areYouSureLoanProduct2')}
            </div>
            <div style={{ marginTop: '12px', marginBottom: '12px' }}>
              <span>
                {translate('loanApplication.step2.loanProductChangeNotice')}
              </span>
              <ul>
                <li>
                  {translate(
                    'loanApplication.step2.loanProductChangeDocumentResigning'
                  )}
                </li>
              </ul>
              <span>
                {translate(
                  'loanApplication.step2.loanProductChangeCommunicate'
                )}
              </span>
            </div>
            <span>
              {translate('loanApplication.step2.loanProductChangeThankYou')}
            </span>
          </div>
        )}
      </Dialog>
    </Dialog>
  )
}

ChangeLoanProductModal.propTypes = {
  dispatch: PropTypes.func.isRequired,
  loanApplicationId: PropTypes.string.isRequired,
  isOpen: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  loanName: PropTypes.string.isRequired,
  setModalOpen: PropTypes.func.isRequired,
}

export default ChangeLoanProductModal
