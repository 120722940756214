import React, { useEffect, useMemo, useRef, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import cx from 'classnames'
import PropTypes from 'prop-types'
import { useStore } from 'store'
import ProgressBar from 'components/progress-bar'
import Loader from 'components/loader'
import { useEffectOnUpdate } from 'common/hooks'
import { AccessWrapper, hasAccess } from 'common/access'
import { CLAIMS } from 'common/claims'
import { getLoanApplicationLastAvailableStep } from 'common/utils'
import { getAbortController } from 'api-middleware'
import { NOT_FOUND_ROUTE } from 'routes'
import {
  getLoanApplication,
  goToStep,
  resetFlow,
} from 'modules/loan-application/actions'

import CreateLoanApp from 'modules/loan-application/create-loan-app'
import SelectLoan from 'modules/loan-application/select-loan'
import LoanDocuments from 'modules/loan-application/loan-documents'
import LenderAgreement from 'modules/loan-application/lender-agreement'
import InitialAndFinalNTP from 'modules/loan-application/initial-final-ntp'
import NTPInspection from 'modules/loan-application/ntp-inspection'
import { PTOSubmission } from 'modules/loan-application/pto-submission'
import Congrats from 'modules/loan-application/congrats'
import Status from 'modules/loan-application/status'
import {
  LOAN_STATUSES_COLOR_CLASSNAME,
  STEPS_MAP,
  STEPS_MAP_COMMERCIAL,
  STATE_IDS,
  STEPS_MAP_REVERSED,
} from 'modules/loan-application/constants'

import EcgToolbox from './ecg-toolbox'

import './style.scss'

const FlowIndex = ({ isCommercialApp }) => {
  const { state, dispatch } = useStore()
  const {
    crtStep,
    currentState,
    possibleSteps,
    possibleViewOnlySteps,
    loanFormData,
    loanApplicationId,
    selectedLoanProductId,
    selectedLoanProduct,
    achData,
    envelopes,
    isHardCreditPulled,
    statuses,
    reasons,
    loading: loanAppLoading,
    reEvaluationCount,
    ntps,
    inspections,
  } = state.flow

  const { allOrganizations } = state.orgManagement
  const { userData } = state.session
  const isEdit = !!loanApplicationId
  const isPtoNotMandatory = loanFormData.isPtoNotMandatory

  const params = useParams()
  const navigate = useNavigate()

  const userId = userData?.associations[0]?.userId
  const [isEcgModalOpen, setIsEcgModalOpen] = useState(false)

  const viewOnly = useMemo(
    () =>
      currentState.id === STATE_IDS.ApplicationExpired ||
      (possibleViewOnlySteps.includes(crtStep) &&
        !possibleSteps.includes(crtStep)),
    [crtStep, possibleViewOnlySteps, possibleSteps, currentState]
  )
  const isECG = hasAccess(userData, CLAIMS.CAN_DO_EVERYTHING)

  const appInitialized = useRef(false)

  /** On step update, scroll to the top */
  useEffectOnUpdate(() => {
    scrollToTop()
  }, [crtStep])

  /**
   * On app initialize check if the user can access the step he's trying to access and
   * otherwise redirect him to the last available step, computed from the "stateId"
   */
  useEffectOnUpdate(() => {
    if (
      !appInitialized.current &&
      crtStep >= 0 &&
      (possibleSteps.length > 0 || possibleViewOnlySteps.length > 0)
    ) {
      // Once the steps are loaded initialize the app
      appInitialized.current = true

      // We only care to manipulate the current step if we are in edit mode
      if (!isEdit) {
        return
      }

      // Compute last available step based on the current stateId
      const lastAvailableStep = getLoanApplicationLastAvailableStep(
        currentState.id,
        isCommercialApp,
        isPtoNotMandatory
      )

      // If the current step does not exist in the list of possible steps, redirect the user to the last available step
      if (
        !possibleViewOnlySteps.includes(crtStep) &&
        !possibleSteps.includes(crtStep)
      ) {
        return goToStep(
          dispatch,
          navigate,
          lastAvailableStep.idx,
          isCommercialApp
        )
      }
    }
  }, [
    isEdit,
    crtStep,
    currentState.id,
    JSON.stringify(possibleViewOnlySteps),
    JSON.stringify(possibleSteps),
  ])

  /** Method to scroll to the top of the Loan Application */
  const scrollToTop = () => {
    const topNode =
      document.getElementById('status-bar') ||
      document.getElementById('flow__main')
    topNode && topNode.scrollIntoView({ behavior: 'smooth', block: 'center' })
  }

  useEffect(() => {
    return () => {
      getAbortController().abort()
      resetFlow(dispatch)
    }
  }, [])

  useEffect(() => {
    if (loanApplicationId) {
      getLoanApplication(dispatch, loanApplicationId, (err) => {
        if (err?.status === 500) {
          navigate(NOT_FOUND_ROUTE, { replace: true })
        }
      })
    }
  }, [JSON.stringify(userData.claims)])

  const loanAppStatuses = useMemo(() => {
    return statuses.map((s) => {
      const labelClassName = `loan-status ${
        LOAN_STATUSES_COLOR_CLASSNAME[s.value]
      }`
      return { ...s, labelClassName: labelClassName }
    })
  }, [statuses])

  let flowContent
  if (isCommercialApp) {
    return null
  }

  switch (crtStep) {
    case STEPS_MAP.NEW_LOAN_APPLICATION:
      flowContent = (
        <CreateLoanApp
          loanApplicationId={loanApplicationId}
          loanFormData={loanFormData}
          organizations={allOrganizations}
          isHardCreditPulled={isHardCreditPulled}
          userData={userData}
          dispatch={dispatch}
          currentState={currentState}
          globalState={state.global}
        />
      )

      break
    case STEPS_MAP.SELECT_LOAN:
      flowContent = (
        <SelectLoan
          isCommercialApp={isCommercialApp}
          dispatch={dispatch}
          loanApplicationId={loanApplicationId}
          selectedLoanProductId={selectedLoanProductId}
          userData={userData}
          scrollToTop={scrollToTop}
          loanApplicationStateId={currentState.id}
          hasCoBorrower={!!loanFormData?.coBorrowerGeneralDetails}
          reEvaluationCount={reEvaluationCount}
          viewOnly={viewOnly}
        />
      )
      break
    case STEPS_MAP.LENDER_AGREEMENT:
      flowContent = (
        <LenderAgreement
          loanApplicationId={loanApplicationId}
          achData={achData}
          selectedLoanProduct={selectedLoanProduct}
          loanFormData={loanFormData}
          scrollToTop={scrollToTop}
          userData={userData}
          dispatch={dispatch}
          currentState={currentState}
          viewOnly={viewOnly}
          isCommercialApp={isCommercialApp}
        />
      )
      break
    case STEPS_MAP.LOAN_DOCUMENTS:
      flowContent = (
        <LoanDocuments
          loanApplicationId={loanApplicationId}
          envelopes={envelopes}
          selectedLoanProduct={selectedLoanProduct}
          loanFormData={loanFormData}
          currentState={currentState.name}
          dispatch={dispatch}
          achData={achData}
          userData={userData}
          initialFundingAmount={loanFormData.initialFundingAmount}
          isCommercialApp={isCommercialApp}
          viewOnly={viewOnly}
        />
      )
      break
    case STEPS_MAP.NTP:
      flowContent = (
        <InitialAndFinalNTP
          loanApplicationId={loanApplicationId}
          ntps={ntps}
          dispatch={dispatch}
          currentState={currentState.name}
          userData={userData}
          loanStatusId={loanFormData?.loanApplicationStatus.id}
          serviceAddress={loanFormData?.serviceAddress}
          estimatedCombinedIncomeToBeProven={
            loanFormData?.estimatedCombinedIncomeToBeProven
          }
        />
      )
      break
    case STEPS_MAP.NTP_INSPECTION:
      flowContent = (
        <NTPInspection
          loanApplicationId={loanApplicationId}
          dispatch={dispatch}
          userData={userData}
          currentState={currentState}
          inspections={inspections}
        />
      )
      break
    case STEPS_MAP.PTO_SUBMISSION:
      flowContent = (
        <PTOSubmission
          ptoId={loanFormData?.ptoId}
          loanApplicationId={loanApplicationId}
          dispatch={dispatch}
          currentState={currentState}
          userData={userData}
        />
      )
      break
    case STEPS_MAP.CONGRATS:
      flowContent = <Congrats />
      break
    default:
      flowContent = null
  }

  if (!Object.keys(loanFormData).length && crtStep !== 0) {
    return null
  }

  if (loanAppLoading) {
    return (
      <div className="flow">
        <div id="flow__main" className="flow__main">
          <Loader center={true} />
        </div>
      </div>
    )
  }

  if (!isEdit && params?.loanApplicationId !== 'new') {
    // if app is in edit mode, but isEdit from the store is not yet updated
    return (
      <div className="flow">
        <div id="flow__main" className="flow__main">
          <Loader center={true} />
        </div>
      </div>
    )
  }

  return (
    <div className="flow">
      {isEdit && (
        <Status
          isCommercialApp={isCommercialApp}
          userData={userData}
          setIsEcgModalOpen={setIsEcgModalOpen}
          achStatus={achData.status?.id}
          applicantsData={
            loanFormData?.coBorrowerGeneralDetails
              ? [
                  loanFormData.borrowerGeneralDetails,
                  loanFormData.coBorrowerGeneralDetails,
                ]
              : [loanFormData.borrowerGeneralDetails]
          }
          loanData={{
            loanApplicationId: loanApplicationId,
            loanAmount:
              loanFormData?.borrowerGeneralDetails?.loanAmount ||
              loanFormData?.loanAmount,
            emailAddress: loanFormData?.emailAddress,
            mobilePhone: loanFormData?.mobilePhone,
            loanApplicationNumber: loanFormData?.loanApplicationNumber,
            loanName: selectedLoanProduct?.name || 'N/A',
            loanStatus: loanFormData?.loanApplicationStatus,
            salesRepresentativeId: loanFormData?.salesRepresentativeId,
            salesRepresentativeName: loanFormData?.salesRepresentativeName,
            salesOrganizationId: loanFormData?.salesOrganizationId,
            serviceAddress: loanFormData?.serviceAddress,
            loanApplicationState: loanFormData?.loanApplicationState,
            salesOrganizationName: loanFormData?.salesOrganizationName,
            loanApplicationStatusReason:
              loanFormData?.loanApplicationStatusReason,
            loanApplicationStatusReasonNote:
              loanFormData?.loanApplicationStatusReasonNote,
            loanApplicationIconData: {
              isOnHold: loanFormData?.isOnHold,
              loanAmountDecreased: loanFormData?.loanAmountDecreased,
              loanAmountIncreased: loanFormData?.loanAmountIncreased,
              cancelled: loanFormData?.cancelled,
              hasReversal: loanFormData?.hasReversal,
              agingTier: loanFormData?.agingTier,
              isDuplicateAddress: loanFormData?.isDuplicateAddress,
              isDuplicateSsn: loanFormData?.isDuplicateSsn,
              isAddressOverriden:
                loanFormData?.isAddressOverridenFromPropertyTitle,
              isAdverseActionNoticesDelivered:
                loanFormData?.isAdverseActionNoticesDelivered,
            },
            businessOwners: loanFormData?.businessOwners,
            dateCreated: loanFormData?.dateCreated,
            hasReachedNtpSubmitted: loanFormData?.hasReachedNtpSubmitted,
          }}
          achOptInClicked={() =>
            goToStep(
              dispatch,
              navigate,
              isCommercialApp
                ? STEPS_MAP_COMMERCIAL.LENDER_AGREEMENT
                : STEPS_MAP.LENDER_AGREEMENT,
              isCommercialApp
            )
          }
          statuses={loanAppStatuses}
          reasons={reasons}
          currentState={currentState}
        >
          <ProgressBar
            crtStep={crtStep}
            currentState={currentState}
            possibleSteps={possibleSteps}
            possibleViewOnlySteps={possibleViewOnlySteps}
            goToStep={(step) =>
              goToStep(dispatch, navigate, step, isCommercialApp)
            }
            isCommercialApp={isCommercialApp}
            isPtoNotMandatory={isPtoNotMandatory}
          />
        </Status>
      )}

      <div
        id="flow__main"
        className="flow__main"
        data-step={STEPS_MAP_REVERSED[crtStep]}
      >
        <div
          className={cx('flow__content', {
            'flow__content--view-only': viewOnly && !isECG,
          })}
        >
          {flowContent}
        </div>

        {isEdit && isEcgModalOpen && (
          <AccessWrapper claims={CLAIMS.CAN_VIEW_ECG_TOOLBOX}>
            <EcgToolbox
              setIsEcgModalOpen={setIsEcgModalOpen}
              loanApplicationId={loanApplicationId}
              loanFormData={loanFormData}
              currentState={currentState.name}
              dispatch={dispatch}
              userId={userId}
              isCommercialApp={isCommercialApp}
            />
          </AccessWrapper>
        )}
      </div>
    </div>
  )
}
FlowIndex.propTypes = {
  isCommercialApp: PropTypes.bool,
}

export default FlowIndex
