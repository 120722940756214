import Api, { RESPONSE_TYPES } from 'easy-fetch-api'
import { v4 as uuidv4 } from 'uuid'
import Cookie from 'js-cookie'
import {
  BASIC_COOKIE_NAME,
  COOKIE_NAME,
  REFRESH_COOKIE_NAME,
} from 'common/constants'
import { forceLogout, sleep } from 'common/utils'
import { showNotification } from 'modules/global/actions'
import { NOTIFICATION_TYPES } from 'modules/global/notifications'
import { refreshToken } from 'modules/session/actions'

let abortController = new AbortController()
export const getAbortController = () => {
  if (abortController.signal?.aborted) {
    abortController = new AbortController()
  }
  return abortController
}
const ERROR_CODES_TO_SHOW_ERROR_FOR = [400, 403, 404, 500]

let alreadyConfigured = false
let refreshTokenInProgress = false

/**
 * Middleware for the API Calls
 *
 * Proxies which requests should go to which host
 * Passes a random GUID on every call (required by most endpoints)
 * Adds Authorization header if the user is authenticated
 *  - if no auth header, it attempts to generate one from a REFRESH_TOKEN
 *  - while the refresh token is loading, all subsequent calls are blocked
 *  - the only exceptions are /me & /enrich which work with the Basic Token and have to be un-blocked
 *  - the refresh token process is marked as complete only after /enrich finishes
 */
export function configureApiMiddleware(dispatch) {
  if (alreadyConfigured) {
    return
  }
  alreadyConfigured = true

  const originalMakeRequest = Api._makeRequest
  Api._makeRequest = async ({ request, callback, ...rest }) => {
    // Detect which host we should use
    const endpoint = request.url.split('/')[1]
    const host = ENDPOINT_MAPPING[endpoint]
    if (!host) {
      showNotification(dispatch, {
        type: NOTIFICATION_TYPES.NEGATIVE,
        title: `Invalid Host ${host}`,
      })
      return Promise.reject(`Invalid Host ${host}`)
    }

    // Handle null or undefined values for query params
    if (request.query) {
      request.query = Object.fromEntries(
        Object.entries(request.query).filter(
          ([_, value]) => value !== undefined && value !== null
        )
      )
    }

    const callThatWorksWithoutToken = Object.values(
      ENDPOINTS_WITHOUT_AUTH
    ).some((url) => url === request.url)

    // Append host to each URL
    request.url = `${host}${request.url}`

    // Pass a GUID header on each call
    const headers = request.headers || {}
    headers['x-requestid'] = uuidv4()

    // Pass Authorization header on each call
    const isRefreshTokenCall = request.url.includes(
      SPECIAL_ENDPOINTS.REFRESH_TOKEN
    )
    const isEnrichCall = request.url.includes(SPECIAL_ENDPOINTS.ENRICH)
    const callThatWorksWithBasicToken =
      isEnrichCall || request.url.includes(SPECIAL_ENDPOINTS.ME)

    if (refreshTokenInProgress && !callThatWorksWithBasicToken) {
      await _waitUntilRefreshTokenIsFinished()
      // Wait another 100ms for the dispatch to finish setting the new enriched cookie
      await sleep(100)
    }

    if (isRefreshTokenCall && refreshTokenInProgress) {
      return Promise.resolve()
    }
    if (isRefreshTokenCall) {
      // For the refresh-token call we don't need the Auth Token
      refreshTokenInProgress = true
    } else {
      const accessToken = await getAuthorizationToken(
        dispatch,
        callThatWorksWithBasicToken,
        callThatWorksWithoutToken
      )
      if (accessToken) {
        headers.Authorization = `Bearer ${accessToken}`
      }
    }

    const isDevelopEnvironment = process.env.NODE_ENV === 'development'

    // Make the request
    return originalMakeRequest({
      request,
      callback,
      ...rest,
      responseType: RESPONSE_TYPES.raw, // must be last to not be overwritten by ..rest
    })
      .then(async (resp) => {
        if (!resp) {
          return null
        }
        if (!resp.ok) {
          if (ERROR_CODES_TO_SHOW_ERROR_FOR.includes(resp.status)) {
            throw await resp.json()
          } else if (resp.status === 401) {
            // In this case we have to force logout because the user basic token is in an invalid state
            if (callThatWorksWithBasicToken) {
              return forceLogout(dispatch)
            }
            const accessToken = await getAuthorizationToken(
              dispatch,
              false,
              callThatWorksWithoutToken
            )
            if (accessToken) {
              headers.Authorization = `Bearer ${accessToken}`
            }

            return await originalMakeRequest({
              request: { ...request, headers },
              callback,
              ...rest,
              responseType: RESPONSE_TYPES.raw,
            })
          } else {
            throw resp
          }
        } else {
          // Handle no-content requests
          if (resp.status === 204) {
            return null
          }
          // Regular success case
          return resp.json()
        }
      })
      .catch((err) => {
        const body = request.body && JSON.parse(request.body)
        if (body && body._dontShowErrorToast) {
          throw err
        }
        const details = isDevelopEnvironment ? err?.details : ''
        let message =
          err?.title ||
          'An error occurred. Please refresh the page and try again.'
        if (isRefreshTokenCall) {
          message = 'Your session has expired. Please log in again'
          forceLogout(dispatch)
        }
        showNotification(dispatch, {
          type: NOTIFICATION_TYPES.NEGATIVE,
          title: `${message} ${details || ''}`,
        })
        throw err
      })
      .finally(() => {
        // Mark the refresh token as DONE when the enrich call finishes
        if (isEnrichCall && refreshTokenInProgress) {
          refreshTokenInProgress = false
        }
      })
  }
}

const getAuthorizationToken = async (
  dispatch,
  callThatWorksWithBasicToken = false,
  callThatWorksWithoutToken = false
) => {
  if (callThatWorksWithoutToken) {
    return null
  }
  const cookie = Cookie.get(COOKIE_NAME)
  const refreshCookie = Cookie.get(REFRESH_COOKIE_NAME)
  const basicCookie = Cookie.get(BASIC_COOKIE_NAME)

  if (cookie) {
    return cookie
  } else if (callThatWorksWithBasicToken && basicCookie) {
    return basicCookie
  } else if (refreshCookie) {
    // Doesn't seem like it ever gets here, because a call to get the refresh token is made before making other API calls, but leaving this statement as back-up just in case
    await refreshToken(dispatch)
    // Wait another 200ms for the dispatch to finish setting the new enriched cookie
    await sleep(200)
    return Cookie.get(COOKIE_NAME)
  }
}

async function _waitUntilRefreshTokenIsFinished() {
  while (refreshTokenInProgress) {
    await sleep(200)
  }
}

/**
 * Mapping for Host endpoints. Each mapping contains:
 *    url - URL to be called for this endpoint
 *    forPaths - paths for which this endpoint applies
 */
const HOSTS = {
  IDENTITY: process.env.REACT_APP_HOST_IDENTITY,
  ORGANIZATIONS: process.env.REACT_APP_HOST_ORGANIZATIONS,
  LENDER: process.env.REACT_APP_HOST_LENDER,
  LENDER_V3: process.env.REACT_APP_HOST_LENDER_V3,
  FUNDING: process.env.REACT_APP_HOST_FUNDING,
  UW_HUB: process.env.REACT_APP_HOST_LENDER,
}

const ENDPOINT_MAPPING = {
  Organizations: HOSTS.ORGANIZATIONS,
  Users: HOSTS.ORGANIZATIONS,
  Teams: HOSTS.ORGANIZATIONS,
  Roles: HOSTS.ORGANIZATIONS,
  Utils: HOSTS.ORGANIZATIONS,
  Identity: HOSTS.IDENTITY,
  Lender: HOSTS.LENDER,
  LoanApplication: HOSTS.LENDER_V3,
  History: HOSTS.LENDER,
  LoanProduct: HOSTS.LENDER,
  Notifications: HOSTS.LENDER,
  Envelopes: HOSTS.LENDER,
  DecisioningTemplate: HOSTS.LENDER,
  StipulationTemplate: HOSTS.LENDER,
  Translations: HOSTS.LENDER_V3,
  FundingAutomation: HOSTS.FUNDING,
  UnderwritingHub: HOSTS.UW_HUB,
}

export const SPECIAL_ENDPOINTS = {
  ENRICH: '/Utils/jwt/enrich',
  ME: '/Users/me',
  REFRESH_TOKEN: '/Identity/refresh-token',
}

export const ENDPOINTS_WITHOUT_AUTH = {
  LOGIN: '/Identity/authenticate-user',
  VERIFY_2FA: '/Identity/verify-2fa-code',
  RESET_PASSWORD: '/Users/credentials/begin-reset-password',
}
